<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
    class="mt-6 customDesign"
  >
    <v-row class="mt-6  customRowDesign">
      <v-col
        cols="4"
        class="offset-md-4"
      >
        <div style="text-align:center">
          <h1 style="text-transform:uppercase"> Interface de connexion</h1>
        </div>
        <v-text-field
          v-model="emailInput"
          label="E-mail"
        />
        <v-text-field
          v-model="motDePasseInput"
          label="Mot de passe"
          :type="show3 ? 'text' : 'password'"
          :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show3 = !show3"
        />
        <!-- <v-text-field
          :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="show3 ? 'text' : 'password'"
          name="input-10-2"
          label="Not visible"
          hint="At least 8 characters"
          value="wqfasds"
          class="input-group--focused"
          @click:append="show3 = !show3"
        /> -->
        <v-btn
        class="gradient-1"
          @click="connexion('EJ')"
        >
          Espace-Jantes
        </v-btn>
        <v-btn
        class='gradient-2'
          @click="connexion('DJ')"
        >
          Distri-Jantes
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
  <!-- <v-app> -->
  <!-- <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <dashboard-core-view /> -->

  <!-- <dashboard-core-settings /> -->
  <!-- </v-app> -->
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import VueAxios from 'vue-axios'
  import CryptoJS from 'crypto-js'

  Vue.use(VueAxios, axios)
  export default {
    name: 'PageLogin',

    components: {
      // DashboardCoreAppBar: () => import('./components/core/AppBar'),
      // DashboardCoreDrawer: () => import('./components/core/Drawer'),
      // // DashboardCoreSettings: () => import('./components/core/Settings'),
      // DashboardCoreView: () => import('./components/core/View'),
    },

    data: () => ({
      show3: false,
      expandOnHover: false,
      motDePasseInput: '',
      emailInput: '',
    }),
    methods: {
      connexion: function (webSiteId) {
        var motDePasse = this.motDePasseInput
        var email = this.emailInput
        if (webSiteId === 'EJ') {
          this.$cookie.set('website_id', '1', 1)
        } else if (webSiteId === 'DJ') {
          this.$cookie.set('website_id', '2', 1)
        }

        const cryptedPassword = CryptoJS.AES.encrypt(motDePasse, process.env.VUE_APP_SECRET_KEY).toString()

        Vue.axios.get('https://api.espace-jantes.com/loginAdmin', {
          params: {
            mail: email,
            password: cryptedPassword,
          },
        })
          .then((response) => {
            if (response.data.etat) {
              // Redirection &  enregistrer les données dans vuex
              console.log('La connexion à reussi')
              this.$localStorage.set('connected', true)
              // this.$router.push({ path: '/' })
              this.$router.push({ name: 'Commandes' })
              return true
            }
            console.log('La connexion à échouer')
            return false
          })
      },
    },
  }
</script>
<style scoped>
  .customDesign {
    font-family: sans-serif;
    color:#fff;
    height: 100vh;
    margin: 0;
    padding: 0;
    background:url('~@/assets/bglogin.jpg');
    background-position: center left;
    background-size:cover;
    display: flex;
    align-items: center;
  }
  .customRowDesign > div{
      background: rgba(255,255,255,0.2);
      backdrop-filter: blur(5px);
      margin-left: 33.3% !important;
  }
  .gradient-2 {
  background: linear-gradient(19deg,orangered, white);
  }
  .gradient-1 {
  background: linear-gradient(19deg,aqua, darkblue);
  }
</style>
<!-- https://blog.sqreen.com/authentication-best-practices-vue/ -->
